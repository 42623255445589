<template>
  <div id="content">
    <div style="position:relative;">
      <div class="overlay" :class="{ vidOver: page[0].acf.banner_image__video === 'video'}">
        <template v-if="page[0].acf.banner_image__video === 'video'">
          <div class='mobHide'>
          <video class='vid' muted autoplay='1' loop='0'>
            <source :src="page[0].acf.video">
          </video>
          </div>
          <div class='mobShow'>
            <b-img class="splash" :src="page[0].acf.banner_image"></b-img>
          </div>
        </template>
        <template v-else>
        <b-img class="splash" :src="page[0].acf.banner_image"></b-img>
        </template>
      </div>
      <div class="title">
        <b-container>
          <h1>
            <span class="well">
              WELCOME TO
            </span>
            <br />
            <span class="scot">
              SCOTTISH SWIMMING
            </span>
            <br />
            <span class="tag">
              We are the National Governing Body for Swimming, Para Swimming, Diving, High Diving, Open
              Water Swimming, Water Polo, Artistic Swimming and Masters Swimming in Scotland.
            </span>
          </h1>
          <b-button @click="scroll()" class="choose"
            >CHOOSE YOUR SPORT TO FIND OUT MORE</b-button
          >
          <font-awesome-icon class="icon" :icon="['fa', 'chevron-down']" />
        </b-container>
      </div>
    </div>
    <div class="ourContainer">
      <div>
        <span id="discip" class="anchor"></span>
        <News cat="none" :newsPage="newsPage" />
      </div>
      <div style="margin-bottom:33px;" class="padding ourContainer" >
        <b-row>
          <b-col>
            <h2 class="upcomingEvents">
              Upcoming Events 
            </h2>
          </b-col>
          <b-col>
            <b-nav-item class="all" to="/events">SEE ALL EVENTS</b-nav-item>
          </b-col>
        </b-row>
        <UpComing discipline="none" total="two" />
      </div>
    </div>
    <div class="pad ourContainer" style="padding: 66px;">
        <b-row class="">
          <b-col>
            <h2 class="upcomingEvents">
              Our Sports
            </h2>
          </b-col>
        </b-row>
        <Disciplines />
    </div>
    <!-- <template v-if="page[0].acf.news_letter_sign_up === true">
      <div>
        <SignUp />
      </div>
    </template> -->
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Disciplines from "@/components/disciplines.vue";
import UpComing from "@/components/upComing.vue";
import News from "@/components/news.vue";
// import SignUp from "@/components/signUp.vue";
export default {
  name: "home",
  components: { Disciplines, UpComing, News },
  data() {
    return {
      page: [],
      newsPage: false,
    };
  },
  methods: {
    scroll() {
      var discip = document.getElementById("discip");
      discip.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/pages?slug=" + this.$route.name)
      .then(({ data }) => {
        this.page = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
::v-deep .grey {
background-color: transparent;
}
h2.upcomingEvents {
  font-weight: bold;
}
.btn-secondary {
  border-color: transparent;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 52%;
  width: 100%;
  text-align: center;
  height: 75px;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;

  text-shadow: 0px 3px 6px #0000005a;
}
.well {
  font-weight: normal;
  font-size: 2.5rem;
}
.scot {
  font-weight: bold;
  font-size: 3.5rem;
}
.tag {
  font-weight: normal;
  font-size: 1.2rem;
  width: 80%;
  padding-bottom: 20px;
}
.choose {
  background-color: #db044e;
  border-radius: 33px;
  padding: 15px 90px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 40px;
  text-shadow: none;
}
.choose:hover{
  background-color: #009fe3;
}
.icon {
  margin: auto;
  margin-top: 50px;
  display: block;
}
.ourContainer {
  max-width: 1350px;
  margin: 0 auto;
  padding: 0 30px;
}
.pad {
  padding-top: 35px;
}
.all {
  text-align: right;
  padding-right: 20px;
}
.all > a {
  color: #009fe3;
  text-decoration: underline;
  font-weight: bold;
}
.all:hover{
  /* font-weight: bold; */
}
.vid{
  width: 100%;
  opacity: 0.46;
  display: block;
}
.mobShow{
  display: none;
}
@media only screen and (max-width: 1525px) {
.title{
  top: 47%;
}
}
@media only screen and (max-width: 1325px) {
.title{
  top: 40%;
}
}
@media only screen and (max-width: 1175px) {
.title{
  top: 20%;
}
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0;
  }
  .title {
    top: 35%;
  }
  .well {
    font-size: 1.5rem;
  }
  .scot {
    font-size: 2.5rem;
  }
  .tag {
    font-size: 1rem;
    line-height: 1.5;
    display: inline-table;
  }
  .choose {
    font-size: 14px;
    margin-top: 0;
  }
  .mobHide{
    display: none;
  }
  .mobShow{
    display: block;
  }
  ::v-deep .pad {
    padding-top: 150px;
  }
}
@media only screen and (max-width: 500px) {
  ::v-deep .pad {
    padding-left: 0px;
    padding-right: 0px;
  }
  h2.upcomingEvents {
    font-weight: bold;
    font-size: 2rem;
    margin-top: -4px;
  }
  ::v-deep   .ourContainer {
    max-width: none;
    margin: 0px auto;
    padding: 0 0px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (max-width: 480px) {
  .all > a {
    color: #009fe3;
    text-decoration: underline;
    font-weight: bold;
    font-size: 8pt;
  }
  .title {
    top: 0%;
  }
  .well {
    font-size: 1rem;
  }
  .scot {
    font-size: 1.5rem;
  }
  .tag {
    font-size: 0.8rem;
  }
  .choose {
    font-size: 12px;
    margin-top: -8px;
    padding: 15px 30px;
  }
}
</style>
