<template>
  <div v-if="show" id="content" :class="{HomeNoPad : this.$route.name === 'home' }">
    <div id="siteMap">
      <b-row>
        <b-col lg='6' md='6' sm='12'>
          <div class='colOne'>
            <b-img
              class="logo"
              src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/scottish-swimming-300x103-1.png"
            ></b-img>
          </div>
        </b-col>
        <b-col sm='4' lg='2' md='2' cols="12">
          <!-- <b-nav-item class="footLink" to='/about-us'>About Us</b-nav-item>
          <b-nav-item class="footLink" to='/contact-us'>Contact Us</b-nav-item> -->
          <div class="socials desktopHide">
            <b-nav-item class="socialLink" href="https://www.facebook.com/ScottishSwimming" target="_blank"
              ><b-img
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Facebook@2x.png"
              ></b-img
            ></b-nav-item>
            <b-nav-item class="socialLink" href="https://twitter.com/ScottishSwim" target="_blank"
              ><b-img
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Twitter@2x.png"
              ></b-img
            ></b-nav-item>
            <b-nav-item class="socialLink" href="https://www.youtube.com/user/ScottishSwimming" target="_blank"
              ><b-img
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Youtube@2x.png"
              ></b-img
            ></b-nav-item>
            <b-nav-item class="socialLink" href="https://www.instagram.com/scottishswimming/" target="_blank"
              ><b-img
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Instagram@2x.png"
              ></b-img
            ></b-nav-item>
            <b-nav-item class="socialLink" href="https://www.flickr.com/people/scottishswimming/" target="_blank"
              ><b-img
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Flickr.png"
              ></b-img
            ></b-nav-item>
          </div>
          <!-- <div class="socials desktopHide">
            <b-nav-item class="socialLink" href="https://www.facebook.com/ScottishSwimming" target="_blank"
              ><b-img
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Facebook@2x.png"
              ></b-img
            ></b-nav-item>
            <b-nav-item class="socialLink" href="https://twitter.com/ScottishSwim" target="_blank"
              ><b-img
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Twitter@2x.png"
              ></b-img
            ></b-nav-item>
            <b-nav-item class="socialLink" href="https://www.youtube.com/user/ScottishSwimming" target="_blank"
              ><b-img
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Youtube@2x.png"
              ></b-img
            ></b-nav-item>
            <b-nav-item class="socialLink" href="https://www.instagram.com/scottishswimming/" target="_blank"
              ><b-img
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Instagram@2x.png"
              ></b-img
            ></b-nav-item>
            <b-nav-item class="socialLink" href="https://www.flickr.com/people/scottishswimming/" target="_blank"
              ><b-img
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Flickr.png"
              ></b-img
            ></b-nav-item>
          </div> -->
        </b-col>
        <b-col lg='4' md='4' sm='4' cols="12">
          <ul class="footerCountrySwim">
            <b-nav-item class="footLink" href="https://www.britishswimming.org/" target="_blank">British Swimming</b-nav-item>
            <b-nav-item class="footLink" href="https://www.swimming.org/swimengland/" target="_blank">Swim England</b-nav-item>
            <b-nav-item class="footLink" href="https://www.swimwales.org/" target="_blank">Swim Wales</b-nav-item>
          </ul>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg='6' md='6' sm='12'>
          <div>
            <p class="govBody">
              We are the National Governing Body<br>
              for Swimming, Para Swimming, Diving,<br>
              High Diving, Open Water Swimming,<br>
              Water Polo, Artistic Swimming and <br>
              Masters Swimming in Scotland.
            </p>
          </div>
        </b-col>
        <b-col lg='6' md='6' sm='12' cols="12" class="mobileFundingPartners">
          <h2 class="fundingPartners">Our Funding Partners:</h2>
          <b-row class="rowFooterMob">
            <b-col v-for="(spon, index) in sponsors" :key="index" class="colFooterMob">
              <a :href="spon.acf.url">
                <b-img :src="spon.acf.image"></b-img>
              </a>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div id="copyRight">
      <b-row>
        <b-col cols="12" lg="3" md="3">
          <p class='cp'>© Scottish Swimming {{ year() }}</p>
        </b-col>
        <b-col cols="12" lg="9" md="9">
          <div class="outerNavFooter">
            <div>
              <b-nav-item class='ocs siteOCS' to="/terms-and-condition">Terms & Conditions</b-nav-item>
            </div>
            <div>
              <b-nav-item class='ocs siteOCS' to="/sitemap">SiteMap</b-nav-item>
            </div>
            <div>
              <!-- <b-nav-item class='ocs siteOCS' to="/privacy-policy">Privacy Policy</b-nav-item> -->
              <a class='ocs siteOCS ocsandtouch privPol' href="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/08/Members-Privacy-Notice-2022.pdf" target="_blank">Privacy Policy</a>
            </div>
            <div>
              <a class='ocs siteOCS ocsandtouch' href="https://www.thetouchagency.co.uk/" target="_blank">Touch</a> / <a class='ocs siteOCS ocsandtouch' href="https://ocs-sport.com/" target="_blank">OCS-Software</a>
            </div>
          </div>
          <!-- <b-row class="footerBlock">
            <b-col class="colWidthTerms">
              <b-nav-item class='ocs siteOCS' to="/terms-and-condition">Terms & Conditions</b-nav-item>
            </b-col>
            <b-col class="colWidthSite">
              <b-nav-item class='ocs siteOCS' to="/sitemap">SiteMap</b-nav-item>
            </b-col>
            <b-col class="colWidthPrivacy">
              <b-nav-item class='ocs siteOCS' to="/privacy-policy">Privacy Policy</b-nav-item>
            </b-col>
            <b-col class="colWidthOCS">
              <b-nav-item class='ocs siteOCS ocsandtouch'>Touch / OCS-Software</b-nav-item>
            </b-col>
          </b-row> -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
// import SignUp from "@/components/signUp.vue";
// import ClubFinder from "@/components/clubFind.vue";
export default {
  name: "footer",
  components: {
    // SignUp,
    // ClubFinder
  },
  data() {
    return {
      sponsors: [],
      show: false,
    };
  },
  methods: {
    year() {
      var d = new Date();
      var n = d.getFullYear();
      return n;
    },
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/sponsors")
      .then(({ data }) => {
        this.sponsors = data;
        this.loading = false;
        setTimeout(() => {
          this.show = true;
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
a.ocs.siteOCS.ocsandtouch.privPol {
  margin-right: 15px;
}
a.ocs.siteOCS.ocsandtouch {
  margin-top: 7px;
  display: inline-block;
}
.footerCountrySwim {
  float: right;
  padding-right: 30px;
}
.outerNavFooter {
  display: inline-flex;
  float: right;
  padding-right: 30px;
}
.colWidthTerms{
  max-width: 100%;
}
.colWidthSite{
  max-width: 25%;
}
.colWidthPrivacy{
  max-width: 25%;
}
.colWidthOCS{
  max-width: 100%;
}
.ocsandtouch > a {
  padding: 0px;
  padding-top: 8px;
}
h2.fundingPartners {
  color: #fff;
  font-size: 10pt;
  margin-bottom: 23px;
}
.row.rowFooterMob {
  width: 100%;
}
li.footLink > a {
  padding-left: 8px;
  font-weight: 600;
}
p.govBody {
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  /* width: 350px; */
  font-size: 14px;
  padding-top: 8px;
}
.footerBlock {
  text-align: center;
}
.HomeNoPad {
  padding-top: 0px!important;
}
#content {
  padding-left: 20%;
  padding-top: 0px;
}
#sponsors {
  padding: 30px 0;
}
#siteMap {
  background-color: #201546;
  /* padding: 30px 0 50px; */
  padding: 30px 0 35px;
}
.logo {
  width: 225px;
  padding-top: 12px;
}
a {
  color: #fff !important;
}
a:hover {
  color: #009fe3 !important;
}
.footLink {
  font-size: 16px;
}
.socials {
  display: inline-flex;
  padding-top: 8px;
}
.socialLink > a {
  padding: 0;
}
.socialLink > a > img {
  width: 35.1px;
}
.rightBar {
  border-right: 1px solid #009fe3;
}
#copyRight {
  background-color: #1b113e;
  /* padding: 20px 20px 30px; */
  color: #fff;
  padding-top: 8px;
}
.cp{
  padding-left: 40px;
  font-size: 14px;
  margin-top: 8px;
}
.ocs{
  /* text-align: end; */
  font-size: 13px;
}
.colOne{
  text-align: left;
  width: 100%;
  padding-left: 30px;
}
@media only screen and (min-width: 991px) {
  .mobileOnly {
    display: none;
  }
}

@media only screen and (max-width: 1300px) {
  .socialLink > a > img {
    width: 25.1px;
  }
}
@media only screen and (max-width: 990px) {
  .footerCountrySwim {
    float: left;
    padding-right: 0;
    padding-left: 0px;
  }
  .desktopHide {
    display: none;
  }
  #content {
    padding-left: 0;
  }
  #siteMap {
    padding: 10px;
  }
}
@media only screen and (max-width: 500px) {
  ::v-deep .colFooterMob > a > img {
    width: 100px;
  }
  .outerNavFooter {
    display: block;
    float: none;
    /* padding-right: 30px; */
    padding-left: 40px;
    padding-bottom: 20px;
  }
  .siteOCS > a {
    padding: 0px;
    padding-bottom: 10px;
  }
  .colWidthTerms{
    text-align: left;
  }
  .colWidthSite{
    max-width: 100%;
    text-align: left;
  }
  .colWidthPrivacy{
    max-width: 100%;
    text-align: left;
  }
  .colWidthOCS{
    max-width: 100%;
    text-align: left;
  }
  .mobileFundingPartners {
    padding-left: 50px;
  }
  .logo {
    padding-bottom: 40px;
    padding-left: 0px;
  }
  .footLink {
    font-size: 14px;
    padding-left: 25px;
    padding-right: 25px;
  }
  p.govBody {
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    width: auto;
    padding-top: 30px;
    font-size: 14px;
  }
  .rightBar {
    border-right: 0px solid #009fe3;
  }
  .rowFooterMob {
    display: block;
    margin-bottom: 50px;
  }
  .colFooterMob {
    display: inline-grid;
    width: auto;
    margin-bottom: 20px;
  }
  .socials.mobileOnly {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .cp{
    padding-left: 40px;
    font-size: 14px;
  }
  .footerBlock {
    text-align: center;
    display: inline-block;
    padding-left: 40px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .colOne{
    text-align: left;
  }
  .siteOCS > a {
    text-align: left;
    padding-left: 0px;
  }
}

</style>
