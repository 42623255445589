import axios from "axios";

class apiCall {
  wpInfo(json) {
    const api_url = process.env.VUE_APP_API_URL;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(json);
  }
}

export default new apiCall();
