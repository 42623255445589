<template>
  <div id="app">
    <Menu />
    <div class="item">
      <div class="shareButtonArrow">
        <font-awesome-icon class="icon rightIcon" :icon="['fa', 'arrow-right']"/> <p class="psharebutton">Share</p>
      </div>
      <div class="socailIcons-share">
        <div>
          <a href="https://www.facebook.com/ScottishSwimming" target="_blank">
            <img class="SocailIcon" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Facebook@2x.png">
          </a>
        </div>
        <div>
          <a href="https://twitter.com/ScottishSwim" target="_blank">
            <img class="SocailIcon" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Twitter@2x.png">
          </a>
        </div>
      </div>
    </div>
    <div class="shareButton">
    </div>
    <transition appear>
      <router-view :key="$route.fullPath" />
    </transition>
  </div>
</template>
<style scoped>
@import "https://live-scotswim-full.ocs-software.com/wp-content/plugins/stachethemes_event_calendar/assets/css/front/style.widget-events.min.css?ver=3.2.7";
@import "https://live-scotswim-full.ocs-software.com/wp-content/plugins/stachethemes_event_calendar/assets/css/fonts/css/all.min.css?ver=3.2.7";
</style>
<script>
import apiCall from "@/services/api.js";
import Menu from "@/components/menu.vue";
export default {
  components: { Menu },
  data() {
    return {
      page: [],
      isActive: true,
      show: true,
      title: '',
      wordpressTitle: '',
      metaYoastsitename: '',
      // loading: false
    };
  },
  metaInfo: {
    title: 'Default App Title',
    titleTemplate: '%s | Scottish Swimming'
  },
  methods: {
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },
    Colorcss: function(){
      this.isActive = !this.isActive;
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo(
        "wp/v2/pages?slug=" + this.itemLink(this.$route.name)
      );
      this.page = first_call.data;
      this.titleRendered = this.page[0].title.rendered;
      this.yoastHead = this.page[0].yoast_head;
      this.title = this.page[0].yoast_head_json.title;
      console.log("this.title -- line 105")
      console.log(this.title)
      this.wordpressTitle = this.page[0].title.rendered;
      console.log("this.wordpressTitle --- line 76")
      console.log(this.wordpressTitle)
      this.metaYoasturl = this.page[0].yoast_head_json.og_url;
      this.metaYoastsitename = this.page[0].yoast_head_json.og_site_name;
      this.metaYoastmodtime = this.page[0].yoast_head_json.article_modified_time;
      this.metaYoasttwitter = this.page[0].yoast_head_json.twitter_card;
      this.metaYoastcanonical = this.page[0].yoast_head_json.canonical; 
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style>
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.item {
  color: #fff;
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  background: #db044e;
  padding-right: 40px;
  padding-left: 40px;
  border-radius: 0px 0px 0px 70px;
  cursor: pointer;
  transition-duration: 0.9s;
}
.item:hover {
  padding-right: 5em;
}
/* .item::before {
  content: "Hover over me!"
} */
.item:hover > .socailIcons-share {
  display: inline-flex!important;
  float: right;
  margin-top: 5px;
  padding-left: 18px;
}
.socailIcons-share {
  display: none;
}
img.SocailIcon {
  width: 40px;
}
svg.icon.rightIcon.svg-inline--fa.fa-arrow-right.fa-w-14 {
  margin-top: 4px;
}
.psharebutton {
  padding-left: 20px;
  margin-bottom: 0px;
}
.shareButtonArrow {
  display: inline-flex;
  padding-top: 10px;
  padding-bottom: 10px;
}
.shareButton {
  color: #fff;
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  background: #db044e;
  padding-right: 40px;
  padding-left: 40px;
  border-radius: 0px 0px 0px 70px;
  cursor: pointer;
}
.shareButton:hover {
  /* padding-right: 130px; */
  padding-right: 20px;
}
.hide {
  display: none;
}
.shareButton:hover + .hide {
  display: inline-flex;
  position: absolute;
  right: 10px;
  z-index: 9;
  top: 5px;
}
.shareButton:hover:after {
  opacity: 1;
  display: inline-block;
}
.shareButton:after {
  content: 'This Page';
  opacity: 0;
  position: relative;
  top: -2px;
  padding-left: 5px;  
  display: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-active {
  transition-delay: 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#app {
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
li {
  list-style: none;
}
img {
  width: 100%;
}
.side {
  display: inline-flex;
}
.wpContent > h2 {
  padding: 30px 0;
  color: #201546;
  font-size: 24px;
  font-weight: 500;
  font-family: GT-Haptik-Medium;
}
::v-deep .wpContent > h2 > strong{
  font-weight: 500!important;
}
@font-face {
  font-family: "GT-Haptik-Medium-Rotalic";
  font-style: normal;
  font-weight: normal;
  src: local("GT Haptik Medium Rotalic"),
    url("./assets/fonts/GT-Haptik/GT-Haptik-Medium-Rotalic.woff") format("woff");
}
@font-face {
  font-family: "GT-Haptik-Medium-Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("GT Haptik Medium Oblique"),
    url("./assets/fonts/GT-Haptik/GT-Haptik-Medium-Oblique.woff") format("woff");
}
@font-face {
  font-family: "GT-Haptik-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("GT-Haptik-Medium"),
    url("./assets/fonts/GT-Haptik/GT-Haptik-Medium.woff") format("woff");
}

@media only screen and (max-width: 990px) {
  .shareButton {
    display: none!important;
  }
  .item {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .shareButton {
    display: none;
  }
}
</style>