<template>
  <div>
    <!-- Mobile Menu update -->
    <div class="mobMenu">
      <b-navbar toggleable="lg" type="dark">
        <b-navbar-brand to="/" @click="$bvModal.hide('nav-collapse')" onClick="window.location.reload();">
          <b-img
            class="logo"
            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/scottish-swimming-300x103-1.png"
          ></b-img>
        </b-navbar-brand>

        <b-navbar-toggle v-b-modal.nav-collapse></b-navbar-toggle>
      </b-navbar>
      <b-modal hide-header hide-footer content-class="test" id="nav-collapse">
        <b-navbar-brand to="/" @click="$bvModal.hide('nav-collapse')" onClick="window.location.reload();">
          <b-img
            class="logo"
            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/scottish-swimming-300x103-1.png"
          ></b-img>
        </b-navbar-brand>
        <b-button class="closeBtn" @click="$bvModal.hide('nav-collapse')" 
          >X</b-button
        >
        <b-input-group class="mt-3">
          <b-form-input
            v-model="text"
            placeholder="Search"
            @keyup.enter="$bvModal.hide('nav-collapse'), goSearch()"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              class="search"
              :to="{
                name: 'search',
                query: { search: text }
              }"
              ><font-awesome-icon class="icon" :icon="['fa', 'search']"
            /></b-button>
          </b-input-group-append>
        </b-input-group>
        <div id="accordion" class="accordion-container">
          <ul
            v-for="(main, index) in mainMenu"
            :key="index"
            class="FirstUL-layer"
          >
            <li class="accordion__item" style="color:#fff">
              <span class="accordion accordion__trigger" :class="{'accordion__trigger_active': visible===index}" @click="visible=index, $bvModal.hide('nav-collapse')">
                <b-link :to="main.slug">{{ main.title }}</b-link>
              </span>
              <div class="accordion__content">
                <div class="menu-sub-list" v-show="visible===index">
                  <ul class="accordion__item" v-for="(sub, index) in main.child_items" :key="index" >
                    <span class="accordion accordion__trigger" :class="{'accordion__trigger_active': visibletwo===index}" @click="visibletwo=index, $bvModal.hide('nav-collapse')">
                      <b-link :to="main.slug.replace(main.slug , '') + '/' + main.slug + '/' + sub.slug ">{{ sub.title }}</b-link>
                    </span>
                    <div class="accordion__content">
                      <div class="menu-sub-list" v-show="visibletwo===index">
                        <ul class="sub-list-third" v-for="(subchild, index) in sub.child_items" :key='index'>
                          <b-link :to="main.slug.replace(main.slug , '') + '/' + main.slug + '/' + sub.slug + '/' + subchild.slug" @click="$bvModal.hide('nav-collapse')">{{ subchild.title }}</b-link>
                        </ul>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <b-nav vertical  >
          <div class="socialLinks">
            <b-nav-item
              href="https://scottishswimming.justgo.com/Account.mvc/Login?ReturnUrl=%2f"
              target="_blank"
            >
              <b-img
                class="login"
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Member_Icon@2x.png"
              ></b-img
              >Login</b-nav-item
            >
          </div>
          <div class="socialLinks FindClub">
            <b-nav-item
              href="/find-a-club"
              target="_blank"
            >
              <b-img
                class="login"
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/04/Icon-ionic-ios-pin_2.png"
              ></b-img
              >Club Finder</b-nav-item
            >
          </div>

          <div class="accordion" role="tablist" v-for="(main, index) in SecondMenu" :key="index">
            <b-card no-body class="mb-1 mobCardBlock">
              <template v-if="main.title === 'Partners'">
                <b-card-header header-tag="header" class="p-1 mobHeader" role="tab">
                  <b-button style="color: rgba(255,255,255,0.45)!important;" class="titleMobbutton secondmobBTN" block :to="main.slug" variant="info" @click="$bvModal.hide('nav-collapse')">{{main.title}}</b-button>
                </b-card-header>
              </template>
              <template v-else-if="main.title === 'Membership Check'">
                <b-card-header header-tag="header" class="p-1 mobHeader" role="tab">
                  <b-button style="color: rgba(255,255,255,0.45)!important;" class="titleMobbutton secondmobBTN" block :to="main.url" variant="info" @click="$bvModal.hide('nav-collapse')">{{main.title}}</b-button>
                </b-card-header>
              </template>
              <template v-else-if="main.title === 'Members Login'">
                <b-card-header header-tag="header" class="p-1 mobHeader" role="tab">
                  <b-button style="color: rgba(255,255,255,0.45)!important;" class="titleMobbutton secondmobBTN" block :href="main.url" variant="info" @click="$bvModal.hide('nav-collapse')">{{main.title}}</b-button>
                </b-card-header>
              </template>
              <template v-else>
                <b-card-header header-tag="header" class="p-1 mobHeader" role="tab">
                  <!-- <b-button style="color: rgba(255,255,255,0.45)!important;" class="titleMobbutton secondmobBTN" block v-b-toggle='main.post_name' variant="info">{{main.title}}</b-button> -->
                  <b-button style="color: rgba(255,255,255,0.45)!important;" class="titleMobbutton secondmobBTN" block :href="main.slug" variant="info" @click="$bvModal.hide('nav-collapse')">{{main.title}}</b-button>
                </b-card-header>
              </template>
              <b-collapse v-for="(sub, index) in main.child_items" :key='index' v-bind:id="sub.menu_item_parent" accordion="my-accordionsecond" role="tabpanel">
                <b-card-body class="divmobSub">
                    <template v-if="sub.title !== 'Governance'">
                      <b-link class="mobSubtitle" :to="'/' + main.slug + '/' + sub.slug" @click="$bvModal.hide('nav-collapse')">{{sub.title}}</b-link>
                    </template>
                    <template v-else>
                      <b-link class="mobSubtitle" v-b-toggle='sub.post_name'>{{sub.title}}</b-link>
                    </template>
                    <div class="outerSubchildBlock">
                    <b-collapse v-for="(subchild, index) in sub.child_items" :key='index' v-bind:id="subchild.menu_item_parent" accordion="my-accordionthird" role="tabpanel">
                      <b-card-body class="divmobSubchild">
                        <b-link :to="'/' + main.slug + '/' + sub.slug + '/' + subchild.slug" @click="$bvModal.hide('nav-collapse')" class="mobSubchildtitle" >{{subchild.title}}</b-link>
                      </b-card-body>
                    </b-collapse>
                    </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>


          <div class="socialLinks">
            <a href="https://www.facebook.com/ScottishSwimming" target="_blank">
              <b-img
                class="social"
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Facebook@2x.png"
              ></b-img>
            </a>
            <a href="https://twitter.com/ScottishSwim" target="_blank">
              <b-img
                class="social"
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Twitter@2x.png"
              ></b-img>
            </a>
            <a
              href="https://www.youtube.com/user/ScottishSwimming"
              target="_blank"
            >
              <b-img
                class="social"
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Youtube@2x.png"
              ></b-img>
            </a>
            <a
              href="https://www.instagram.com/scottishswimming/"
              target="_blank"
            >
              <b-img
                class="social"
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Instagram@2x.png"
              ></b-img>
            </a>
            <a
              href="https://www.flickr.com/people/scottishswimming/"
              target="_blank"
            >
              <b-img
                class="social"
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Flickr.png"
              ></b-img>
            </a>
          </div>
        </b-nav>
      </b-modal>
    </div>
    <!-- End Mobile Menu -->

    <!-- Desktop Menu -->
    <div class="sideMenu">
      <b-nav-item to="/"  onClick="window.location.reload();">
        <b-img
          class="logo homeLogo"
          src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/scottish-swimming-300x103-1.png"
        ></b-img>
      </b-nav-item>
      <b-input-group class="mt-3">
        <b-form-input
          class="inputFormC"
          v-model="text"
          placeholder="Search"
          @keyup.enter="goSearch()"
        ></b-form-input>
        <b-input-group-append>
            <b-button
              class="search"
              @click="goSearch()"
              ><font-awesome-icon class="icon" :icon="['fa', 'search']"
            /></b-button>
        </b-input-group-append>
      </b-input-group>
      <div class="outerBottomLineBreaker">
        <div class="bottomLineBreaker"></div>
      </div>
      <b-nav vertical>
        <div v-for="(main, index) in mainMenu" :key="index">
          <template v-if="main.title === 'News'">
            <b-link @click="closeAll" v-if="main.type === 'post_type' " :to="'/' + main.slug" v-b-toggle="'collapse'"  class="m-1 nobutn" v-html="main.title"></b-link>
          </template>
          <template v-else>
            <b-link v-if="main.type === 'post_type' " :to="'/' + main.slug" v-b-toggle="main.post_name" class="m-1 nobutn" v-html="main.title"></b-link>
            <b-link v-else :to="'/' + main.url" v-b-toggle="main.post_name" class="m-1 nobutn" v-html="main.title"></b-link>
          </template>
          <b-collapse v-model="main.show" v-for="(sub, index) in main.child_items" :key="index" v-bind:id="sub.menu_item_parent" accordion="my-accordion" role="tabpanel">
            <template v-if="sub.type === 'post_type'">
              <b-link class="secondLayer" :to="'/' + main.slug + '/' + sub.slug" :class="sub.classes">
                <b-card v-b-toggle="sub.post_name" class="subTitle" v-html="sub.title"></b-card>
              </b-link>
            </template>
            <template v-else>
              <b-link :href="sub.url" target="_blank" :class="sub.classes">
                <b-card v-b-toggle="sub.post_name" class="subTitle" v-html="sub.title"></b-card>
              </b-link>               
            </template>
            <b-collapse v-for="(subchild, index) in sub.child_items" :key="index" v-bind:id="subchild.menu_item_parent" accordion="my-accordiontwo" role="tabpanel">
              <template v-if="subchild.title === 'Members Portal' ">
                <b-link class="thirdLayer" :href="subchild.url" target="_blank" :class="subchild.classes">
                  <b-card v-b-toggle="subchild.post_name" class="subchildTitle" v-html="subchild.title"></b-card>
                </b-link>
              </template>
              <template v-else>
                <b-link class="thirdLayer" :to="'/' + main.slug + '/' + sub.slug + '/' + subchild.slug" :class="subchild.classes">
                  <b-card v-b-toggle="subchild.post_name" class="subchildTitle" v-html="subchild.title"></b-card>
                </b-link>
              </template>
              <b-collapse v-for="(fourthsubchild, index) in subchild.child_items" :key="index" :id="fourthsubchild.menu_item_parent">
                <b-link class="fouthLayer" :to="'/' + main.slug + '/' + sub.slug + '/' + subchild.slug + '/' + fourthsubchild.slug" :class="fourthsubchild.classes">
                  <b-card class="fourthchildTitle" v-html="fourthsubchild.title"></b-card>
                </b-link>
              </b-collapse>
            </b-collapse>
          </b-collapse>
        </div>
        <div class="outerBottomLineBreaker">
          <div class="bottomLineBreaker"></div>
        </div>
        <div class="socialLinks">
          <b-nav-item
            href="https://scottishswimming.justgo.com/Account.mvc/Login?ReturnUrl=%2f"
            target="_blank"
          >
            <b-img
              class="login"
              src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Member_Icon@2x.png"
            ></b-img
            >Login</b-nav-item
          >
        </div>
        <div class="FindClub">
          <b-nav-item
            href="/find-a-club"
            target="_blank"
          >
            <b-img
              class="login Cfinder"
              src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/04/Icon-ionic-ios-pin_2.png"
            ></b-img
            >Club Finder</b-nav-item
          >
        </div>
        <div class="outerBottomLineBreaker">
          <div class="bottomLineBreaker"></div>
        </div>
        <div v-for="(main, index) in SecondMenu" :key="index">
          <template v-if="main.title === 'Members Login'">
            <b-link :href="main.url" target="_blank"  class="m-1 secondnobutn" v-html="main.title"></b-link>
          </template>
          <template v-else>
            <b-link v-if="main.type === 'post_type' " :to="'/' + main.slug" v-b-toggle="main.post_name"  class="m-1 secondnobutn" v-html="main.title"></b-link>
            <b-link v-else :to="'/' + main.url" v-b-toggle="main.post_name" class="m-1 secondnobutn" v-html="main.title"></b-link>
          </template>
          <b-collapse v-model="main.show" v-for="(sub, index) in main.child_items" :key="index"  v-bind:id="sub.menu_item_parent" accordion="my-accordiontwo" role="tabpanel">
            <b-link class="secondLayer" :to="'/' + main.slug + '/' + sub.slug" :class="sub.classes">
              <b-card v-b-toggle="sub.post_name" class="subTitle" v-html="sub.title"></b-card>
            </b-link>
            <b-collapse v-for="(subchild, index) in sub.child_items" :key="index" :id="subchild.menu_item_parent" >
              <b-link :to="'/' + main.slug + '/' + sub.slug + '/' + subchild.slug" class="thirdLayer" :class="subchild.classes">
                <b-card v-b-toggle="subchild.post_name" class="subchildTitle" v-html="subchild.title"></b-card>
              </b-link>
              <b-collapse v-for="(fourthsubchild, index) in subchild.child_items" :key="index" :id="fourthsubchild.menu_item_parent">
                <b-link class="fouthLayer" :to="'/' + main.slug + '/' + sub.slug + '/' + subchild.slug + '/' + fourthsubchild.slug" :class="fourthsubchild.classes">
                  <b-card class="fourthchildTitle" v-html="fourthsubchild.title"></b-card>
                </b-link>
              </b-collapse>
            </b-collapse>
          </b-collapse>
        </div>


        <div class="socialLinks mainsocial">
          <a href="https://www.facebook.com/ScottishSwimming" target="_blank">
            <b-img
              class="social"
              src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Facebook@2x.png"
            ></b-img>
          </a>
          <a href="https://twitter.com/ScottishSwim" target="_blank">
            <b-img
              class="social"
              src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Twitter@2x.png"
            ></b-img>
          </a>
          <a
            href="https://www.youtube.com/user/ScottishSwimming"
            target="_blank"
          >
            <b-img
              class="social"
              src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Youtube@2x.png"
            ></b-img>
          </a>
          <a href="https://www.instagram.com/scottishswimming/" target="_blank">
            <b-img
              class="social"
              src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Instagram@2x.png"
            ></b-img>
          </a>
          <a
            href="https://www.flickr.com/people/scottishswimming/"
            target="_blank"
          >
            <b-img
              class="social"
              src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Flickr.png"
            ></b-img>
          </a>
        </div>
      </b-nav>
    </div>
    <!-- End Desktop Menu -->
  </div>
</template>

<script>
import { bus } from "../main.js";
import apiCall from "@/services/api.js";
export default {
  name: "menu",
  components: {  },
  data() {
    return {
      mainMenu: [],
      secondMenu: [],
      search:'',
      open: false,
      visible: null,
      visibletwo: null
    };
  },
  methods: {
    goSearch() {
      this.$router.push({ name: "search", query: { search: this.text } });
    },
    async fetchPage() {
      try {
        // First call, await for call to return
        let first_call = await apiCall.wpInfo('menus/v1/menus/PrimaryMenu');
        this.mainMenu = first_call.data[0];
        this.loading = false;
        // Second call, await for call to return
        let second_call = await apiCall.wpInfo('menus/v1/menus/SecondaryMenu');
        this.secondMenu = second_call.data;
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    },
    closeAll() {
       this.mainMenu.forEach(main => {
          main.show = false
       })
    },
    closeSecondMenu() {
       this.secondMenu.forEach(main => {
          main.show = false
       })
    },
    six(){
      console.log("OPEN")
      this.open = true;
      console.log("this.open ---")
      console.log(this.open)
      var isMobileVersion = document.getElementsByClassName('router-link-active');
      if (isMobileVersion.length > 0) {
        console.log("TRUE")
        this.$bvModal.hide('nav-collapse')
      } else {
        console.log("FALSE")
        this.$bvModal.show('nav-collapse')
      }
    }
  },
  mounted() {
    apiCall
      .wpInfo("menus/v1/menus/PrimaryMenu?per_page=100")
      .then(({ data }) => {
        this.mainMenu = data.items;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  created() {
    bus.$on("Picked", (data) => {
      (this.picked = data), this.fetchPage();
    });
    apiCall
      .wpInfo("menus/v1/menus/SecondaryMenu?per_page=100")
      .then(({ data }) => {
        this.SecondMenu = data.items;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  computed: {
    currentItem() {
      return this.mainMenu.find(menu => menu.title === this.$route.name)
    }
  }
};
</script>

<style scoped>

/* new mobile code */
::v-deep ul.sub-list-third > .router-link-exact-active.router-link-active{
  color: #fff !important;
  background: #009fe3;
  font-weight: bold;
}
ul.sub-list-third {
  background: #67c7f0!important;
  color: #fff;
}
ul.sub-list-third > a {
  color: #fff;
  font-size: 14px;
  padding-left: 20px;
  padding-bottom: 10px;
  display: block;
  padding-top: 10px;
}
.menu-sub-list > ul{
  padding-left: 0px;
  font-size: 1rem;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  border-top: 1px solid;
  /* margin-right: 4px; */
  margin-right: 0px;
  margin-top: 0px;
  /* padding-bottom: 11px; */
  /* padding-top: 10px; */
  background: #40c5ff;
}
span.accordion.accordion__trigger > a{
  color: #fff!important;
  font-size: 1rem;
  /* padding-left: 15px; */
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: block;
}
.FirstUL-layer {
  padding-left: 0;
  border-top: 1px solid #009fe3;
  /* padding-top: 15px; */
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 0;
}
.accordion__trigger_active > a {
  font-size: 18px!important;
}
.accordion__trigger_active {
  color: #fff;
  font-size: 1rem;
  background-color: #009fe3;
  border-color: #009fe3;
  width: 100%;
  display: block;
  font-weight: bold;
}
/* end of new mobile code */

/* mobile code */
button.btn.titleMobbutton.secondmobBTN.btn-info.btn-block.collapsed {
  color: rgba(255,255,255,0.45);
}
button.btn.titleMobbutton.btn-info.btn-block.collapsed {
  padding-bottom: 13px;
}
button.btn.titleMobbutton.btn-info.btn-block.collapsed {
  margin-bottom: -10px;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff!important;
  background-color: #009fe3;
  border-color: #009fe3;
}
.btn-info {
  color: #fff;
  background-color: transparent;
  border-color: #009fe3;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  border-bottom: 0;
  text-align: left;
}
.btn-info:hover {
  color: #fff;
  background-color: #009fe3;
  border-color: #009fe3;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #009fe3;
  border-color: #009fe3;
  box-shadow: 0 0 0 0.2rem rgb(58 176 195 / 50%);
}
button.btn.btn-info.btn-block.not-collapsed {
  background-color: #009fe3;
  color:#fff!important;
}
a.mobSubtitle {
  padding-left: 10px;
  color: #fff;
}
.card-body.divmobSub {
  border-top: 1px solid;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: -5px;
  padding-bottom: 5px;
  padding-top: 10px;
  background: #40c5ff;
}
.card-body.divmobSubchild {
  border-top: 1px solid;
  padding-bottom: 15px;
  padding-top: 6px;
  background: #67c7f0;
  font-size: 10pt;
}
a.mobSubchildtitle {
  padding-left: 10px;
  color: #fff;
  margin-top: 6px;
  display: block;
}
.outerSubchildBlock {
  margin-top: 10px;
}
.card-body.divmobSub > .router-link-exact-active.router-link-active[data-v-cd4483fe] {
  color: #fff!important;
}
/* end of mobile code */


.mainsocial {
  margin-left: 10px;
}
.outerBottomLineBreaker {
  margin-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  margin-bottom: 10px;

}
.bottomLineBreaker {
  border-bottom: 1px solid;
}

a.mobhide {
  display: none;
}
a.secondLayer.hidemob {
  display: none;
}
/* .card.subTitle.collapsed.HideMobile {
  display: none;
} */
::v-deep div#\31 3256 {
  display: none;
}
a.secondLayer.router-link-exact-active.router-link-active > div {
  color: #009fe3!important;
}
a.secondLayer.router-link-exact-active.router-link-active > div{
  color: #009fe3!important;
}
a.thirdLayer.router-link-exact-active.router-link-active >  div{
  color: #009fe3!important;
}
.secondnobutn{
  background: transparent;
  border: none;
  text-transform: capitalize;
  color:rgba(255,255,255,0.45);
  font-size: 14px;
  font-family: GT-Haptik-Medium;
  text-align: left;
  display: block;
  padding: 0rem 1rem;
  text-decoration: none;
}
::v-deep .nav-linkNew > a {
  padding: 0px!important;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: transparent;
  border-color: none;
  color: #009fe3;
}
::v-deep .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  /* border-radius: 0.25rem; */
  color: #fff;
  border: none;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.25rem;
  background: none;
}
.nobutn{
  background: transparent;
  border: none;
  /* text-transform: capitalize; */
  color: #fff;
  font-size: 15px;
  font-family: GT-Haptik-Medium;
  text-align: left;
  display: block;
  padding: 0.3rem 1rem;
  text-decoration: none;
}
::v-deep .not-collapsed {
  color: #009fe3!important;
  font-weight: 700;
}
button.btn.m-1.nobutn.btn-secondary.collapsed:hover {
  background: transparent;
  border: none;
  color: #009fe3;
  font-weight: 700;
}
a.m-1.nobutn.router-link-active {
  color: #009fe3;
  font-weight: 700;
}
a.m-1.secondnobutn.router-link-active {
  color: #009fe3;
  font-weight: 700;
}
.subTitle{
  margin-left: 30px;
  width: 170px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  padding-bottom: 8px;
  padding-top: 8px;
}
.subTitle:hover {
  color: #009fe3;
}
.subchildTitle{
  width: 170px;
  font-size: 14px;
  margin-left: 50px;
  color: #fff;
  text-decoration: none;
  padding-bottom: 8px;
  padding-top: 8px;
}
.subchildTitle:hover{
  color: #009fe3;
}
.fourthchildTitle{
  width: 170px;
  font-size: 14px;
  margin-left: 80px;
  color: #fff;
  text-decoration: none;
  padding-bottom: 8px;
  padding-top: 8px;
}
.fourthchildTitle:hover{
  color: #009fe3;
}
a:hover {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  text-decoration: none;
}
::v-deep a.fouthLayer.router-link-exact-active.router-link-active > .fourthchildTitle {
  color: #009fe3;
  font-weight: 700;
}
.gg-arrow-left:hover {
  color: #009fe3;
}
.gg-arrow-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  color: #fff;
}
.gg-arrow-left::after,
.gg-arrow-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px
}
.gg-arrow-left::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  bottom: 7px
}
.gg-arrow-left::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor
}
.Cfinder {
  margin-top: -2px;
}
::v-deep a.nav-link.router-link-active {
  color: #009fe3 !important;
  font-weight: bold;
}
.sideMenu {
  height: 100%;
  width: 20%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #1b113e;
  overflow-x: hidden;
  padding: 50px 40px;
}
.logo {
  width: 200px;
}
::v-deep .nav-link {
  text-transform: capitalize;
  color: #fff;
  font-size: 15px;
  font-family: "GT-Haptik-Medium";
}
.sub {
  padding-left: 20px;
}
.sub > a {
  font-size: 16px;
}
.subSub {
  padding-left: 40px;
}
.subSub > a {
  font-size: 14px;
}
a:hover {
  color: #009fe3 !important;
  /* font-weight: bold; */
}
.social {
  width: 36px;
}
.socialLinks {
  display: inline-flex;
  margin-top: 10px;
}
.FindClub {
  margin-bottom: 10px;
}
.login {
  width: 25px;
  padding-right: 10px;
}
::v-deep .search {
  background-color: #e8e7ec;
  /* border-radius: 0px 33px 33px 0px; */
  border-radius: 0px;
  border-color: transparent;
  opacity: 0.5;
}
.icon {
  color: #0c9a94;
}
input {
  border-color: #fff;
}
.input-group-append {
  margin-left: 0px;
}
::v-deep .inputFormC{
  border-radius: 0;
  opacity: 0.5;
  background-color: #e8e7ec;
}
.input-group {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
.mobMenu {
  display: none;
}
::v-deep .modal-content {
  background-color: #201646;
  height: 103vh;
}
::v-deep .slide-enter-active {
  -moz-transition-duration: 1s;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

::v-deep .slide-leave-active {
  -moz-transition-duration: 1s;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

::v-deep .slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

::v-deep .slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.router-link-exact-active.router-link-active {
  color: #009fe3 !important;
}
@media only screen and (max-width: 1366px) {
  .sideMenu {
    padding: 50px 0px;
  }
  .logo {
    width: 150px;
  }
}
@media only screen and (max-width: 990px) {
  .outerBottomLineBreaker {
    display: none;
  }
  .logo {
    width: 125px;
    padding-left: 15px;
    margin-top: 15px;
  }
  .socialLinks {
    display: inline-flex;
    margin-top: 10px;
  }
  .subSub {
    padding-left: 20px;
  }
  .sideMenu {
    display: none;
  }
  .mobMenu {
    display: block;
  }
  .navbar {
    background-color: #201646;
  }
  ::v-deep .navbar-toggler-icon {
    background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Menu.png) !important;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 20px;
  }
  ::v-deep .navbar-toggler {
    border: 0;
  }
  ::v-deep .modal-dialog {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }
  .closeBtn {
    color: white;
    z-index: 9999;
    float: right;
    background: transparent;
    border: 0;
    font-size: 34px;
  }
}

@media only screen and (max-width: 500px) {
  ::v-deep .modal-content {
    background-color: #201646;
    height: auto;
  }
  ::v-deep div#nav-collapse___BV_modal_content_ {
    max-width: 100% !important;
  }
  ::v-deep div#nav-collapse {
    max-width: 100% !important;
  }
}
</style>