import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueMeta from "vue-meta";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { BIconDownload, BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import JQuery from "jquery";
// import VueAnalytics from 'vue-analytics';
import Print from 'vue-print-nb';
import iframeResize from 'iframe-resizer/js/iframeResizer';
import VueGtag from "vue-gtag";
import VueHead from 'vue-head'

library.add(faTwitter, faStar, faChevronDown, faSearch, faArrowLeft, faChevronLeft, faArrowRight, faPlus);
Vue.config.productionTip = false;
Vue.use(VueHead)
// Vue.use(VueGtag, {
//   config: { id: "UA-2605636-1" }
// });
Vue.use(Print);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(VueMeta);
// Vue.use(VueMeta, {
//   keyName: 'head'
// })
export const bus = new Vue();
Vue.component('BIconDownload', BIconDownload)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueGtag, {
  config: { id: "G-CW6J0SZ727" },
  includes: [
    { id: 'G-CW6J0SZ727' }
  ]
}, router);

Vue.directive('resize', {
  bind: function(el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el))
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  }
})

new Vue({
  Print,
  router,
  store,
  axios,
  JQuery,
  render: (h) => h(App),
}).$mount("#app");
